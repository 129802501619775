import {
    RESEARCH_CTA_NEW_FEATURE_STORAGE_KEY,
    RESEARCH_OPEN_SIDEBAR_NEW_FEATURE_STORAGE_KEY,
} from '@/lib/constants/research';

export const NewFeatureAnnouncementStorageKey = 'new_feature_announcements';

export enum NewFeatureAnnouncementTypes {
    RESEARCH_CTA = `${RESEARCH_CTA_NEW_FEATURE_STORAGE_KEY}`,
    RESEARCH_SIDEBAR_TOGGLE = `${RESEARCH_OPEN_SIDEBAR_NEW_FEATURE_STORAGE_KEY}`,
}
export const NewFeatureAnnouncementsStorageKeyMap: {
    [key in NewFeatureAnnouncementTypes]: string;
} = {
    [NewFeatureAnnouncementTypes.RESEARCH_CTA]: `${RESEARCH_CTA_NEW_FEATURE_STORAGE_KEY}`,
    [NewFeatureAnnouncementTypes.RESEARCH_SIDEBAR_TOGGLE]: `${RESEARCH_OPEN_SIDEBAR_NEW_FEATURE_STORAGE_KEY}`,
};
