import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { Heading } from '@/components/dom/text-elements';
import BaseModal from '@/components/global/base-modal';
import Button from '@/components/global/button';
import Icon from '@/components/global/icon';
import IconButton from '@/components/ui/IconButton';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { createGmailLink, createLinkedInShareLink, createMailToLink, createTwitterTweet } from '@/helpers/socials';
import * as gtag from '@/lib/gtag';
import { Nullable } from '@/types/nullable';
import { ShareType } from '@/types/share';
import {
    type BaseTrackingProperties,
    type ModalTrackingProperties,
    ModalTypes,
    ShareTrackingProperties,
} from '@/types/tracking';

type TrackingProperties = Omit<BaseTrackingProperties, 'component'> & Partial<ModalTrackingProperties>;

export type ShareModalProps = {
    isOpen: boolean;
    link: string;
    embed?: string;
    closeFn: () => void;
    twitterShareString?: string;
    gtagCategory: string;
    trackingProperties?: TrackingProperties;
    useCurrentUrl?: boolean;
};

type CopyButtonProps = {
    onClick: () => void;
    trackingProperties?: TrackingProperties;
};

const CopyButton = ({ onClick, trackingProperties = {} }: CopyButtonProps) => {
    const { categories, components, eventTypes } = usePosthogTracking();
    const [copied, setCopied] = useState(false);
    const previousCopied = usePrevious(copied);

    // Reset copied state after 3 seconds
    useEffect(() => {
        if (copied && !previousCopied) {
            const timeout = setTimeout(() => {
                setCopied(false);
            }, 3000);
            return () => clearTimeout(timeout);
        }
    }, [copied, previousCopied]);

    return (
        <Button
            className="text-s w-20 !text-brand-blue-nu"
            type="action"
            shadow={false}
            onClick={() => {
                onClick();
                setCopied(true);
            }}
            color="white"
            tracking={{
                eventType: eventTypes.COPY_LINK,
                trackingProperties: {
                    ...trackingProperties,
                    category: categories.BUTTON_CLICK,
                    component: components.COPY_EMBED_GRAPH_BUTTON,
                    label: 'Copy',
                    shareType: ShareType.COPY_LINK,
                },
            }}
        >
            {copied ? (
                <div className="flex items-center text-brand-blue-nu">
                    <Icon
                        type="check"
                        size="sm"
                        color="white"
                        className="mr-2 bg-brand-blue-nu p-1 pt-[5px] rounded-full"
                    />
                    Copied!
                </div>
            ) : (
                <div className="flex items-center text-brand-blue-yves">
                    <Icon
                        type="copy"
                        color="blueYves"
                        className="mr-2"
                    />
                    Copy
                </div>
            )}
        </Button>
    );
};

const ShareModal = ({
    closeFn,
    link,
    embed,
    isOpen,
    twitterShareString,
    gtagCategory,
    trackingProperties = {} as TrackingProperties,
    useCurrentUrl = true,
}: ShareModalProps) => {
    const searchParams = useSearchParams();
    const previousSearchParams = usePrevious(searchParams?.toString());
    const [copyLinkUrl, setCopyLinkUrl] = useState<Nullable<string>>(null);
    const { categories, locations, eventTypes } = usePosthogTracking();
    const embedSnippet = `<iframe width="100%" height="480" src="${embed}" frameborder="0"></iframe>`;

    const closeFnOverride = () => {
        closeFn();
    };

    const copyLink = () => {
        if (typeof window !== 'undefined' && copyLinkUrl) {
            window.navigator.clipboard.writeText(copyLinkUrl);
            gtag.event({
                action: 'Copy',
                category: gtagCategory,
                label: 'Direct Link',
            });
        }
    };

    const copyEmbed = () => {
        if (typeof window !== 'undefined') {
            window.navigator.clipboard.writeText(embedSnippet);
            gtag.event({
                action: 'Copy',
                category: gtagCategory,
                label: 'Direct Link',
            });
        }
    };

    const handleShare = (label: string, shareLink: string) => {
        return () => {
            if (typeof window !== 'undefined') {
                gtag.event({
                    action: 'Share',
                    category: gtagCategory,
                    label,
                });
                window.open(shareLink, '_blank');
            }
        };
    };

    useEffect(() => {
        if (!copyLinkUrl || searchParams?.toString() !== previousSearchParams) {
            const linkUrl = useCurrentUrl ? window.location.href : link;

            setCopyLinkUrl(linkUrl);
        }
    }, [copyLinkUrl, link, previousSearchParams, searchParams, useCurrentUrl]);

    return (
        <BaseModal
            header="Share"
            headerClassName="text-center text-3xl font-brand-bold"
            isOpen={isOpen}
            onRequestClose={closeFnOverride}
            trackingProperties={{
                ...trackingProperties,
                modalType: ModalTypes.SHARE,
            }}
        >
            <div className="py-6 px-2">
                <Heading
                    importance={5}
                    className="text-analyst-lighter-black mb-4"
                >
                    Direct link
                </Heading>
                <div className="flex gap-2 mb-4 w-full">
                    <p className="bg-brand-gray p-3 rounded-lg border border-analyst-lavender break-words w-3/4">
                        {copyLinkUrl}
                    </p>
                    <CopyButton onClick={copyLink} />
                </div>

                <hr className="mb-4" />

                <Heading
                    importance={5}
                    className="text-analyst-lighter-black mb-4"
                >
                    Social media
                </Heading>
                {copyLinkUrl && (
                    <div className="flex gap-3 mb-4">
                        <IconButton
                            iconType="xTwitterSquare"
                            onClick={handleShare(
                                'Twitter',
                                createTwitterTweet(`${twitterShareString}\n${copyLinkUrl}`)
                            )}
                            tracking={{
                                eventType: eventTypes.SHARE,
                                trackingProperties: {
                                    category: categories.SHARE,
                                    section: locations.SHARE_MODAL,
                                    shareType: ShareType.TWITTER,
                                } as ShareTrackingProperties,
                            }}
                            className="p-0"
                            iconProps={{
                                className: 'text-white flex items-center justify-center',
                                iconClassName: 'w-8 h-8',
                            }}
                        />
                        <IconButton
                            iconType="linkedIn"
                            onClick={handleShare(
                                'LinkedIn',
                                createLinkedInShareLink(copyLinkUrl, `Check out this index on Thematic: ${copyLinkUrl}`)
                            )}
                            tracking={{
                                eventType: eventTypes.SHARE,
                                trackingProperties: {
                                    category: categories.SHARE,
                                    section: locations.SHARE_MODAL,
                                    shareType: ShareType.LINKEDIN,
                                } as ShareTrackingProperties,
                            }}
                            className="bg-analyst-blue"
                            iconProps={{
                                className: 'text-white flex items-center justify-center',
                                iconClassName: 'w-5 h-5 ',
                            }}
                        />
                    </div>
                )}

                <hr className="mb-4" />

                <Heading
                    importance={5}
                    className="text-analyst-lighter-black mb-4"
                >
                    Email
                </Heading>
                {copyLinkUrl && (
                    <div className="flex gap-4 mb-4">
                        <Button
                            type="link"
                            openNewWindow={true}
                            href={createMailToLink(
                                undefined,
                                'Check out this Thematic index',
                                `Check out this index on Thematic: ${copyLinkUrl}`
                            )}
                            color="analyst-purple"
                            roundedCorners="lg"
                            tracking={{
                                eventType: eventTypes.SHARE,
                                trackingProperties: {
                                    category: categories.SHARE,
                                    iconType: 'email',
                                    label: 'Email',
                                    section: locations.SHARE_MODAL,
                                    shareType: ShareType.EMAIL,
                                },
                            }}
                        >
                            <Icon
                                type="email"
                                color="white"
                                className="mr-2"
                            />
                            Email
                        </Button>
                        <Button
                            type="link"
                            openNewWindow={true}
                            href={createGmailLink(
                                undefined,
                                'Check out this Thematic index',
                                `Check out this index on Thematic: ${copyLinkUrl}`
                            )}
                            color="analyst-purple"
                            roundedCorners="lg"
                            tracking={{
                                eventType: eventTypes.SHARE,
                                trackingProperties: {
                                    category: categories.SHARE,
                                    iconType: 'gmail',
                                    label: 'Gmail',
                                    section: locations.SHARE_MODAL,
                                    shareType: ShareType.GMAIL,
                                },
                            }}
                        >
                            <Icon
                                type="gmail"
                                color="white"
                                className="mr-2"
                            />
                            Gmail
                        </Button>
                    </div>
                )}
                {embed && (
                    <>
                        <hr className="mb-4" />

                        <Heading
                            importance={4}
                            className="text-brand-blue mb-4"
                        >
                            Embed graph
                        </Heading>
                        <div className="flex mb-4">
                            <p className="bg-brand-gray mr-4 p-2 w-full max-width">{embedSnippet}</p>
                            <CopyButton
                                onClick={copyEmbed}
                                trackingProperties={trackingProperties}
                            />
                        </div>
                    </>
                )}
            </div>
        </BaseModal>
    );
};
export default ShareModal;
